.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blur-12 {
  backdrop-filter: blur(12px);
}

/* tim mamber animation */
.team-member-container .team-member-card {
  transition: transform 4s ease;
}

.team-member-container .team-member-card.active {
  transform: translateX(100%);
}

/* client slider animation */
.slider-container {
  display: grid;
  place-items: center;
}

.slider {
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc(250px * 38);
  animation: scroll 30s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
  cursor: pointer;
}

.slide {
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
  margin-right: 15px;
}

.slider-img {
  transition: transform 0.0001s;
}

.slider-img:hover {
  transform: translateZ(20px);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 11));
  }
}
